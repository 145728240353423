<template>
  <span :class="[`Icon Icon--${name}`, `Icon--${size}`]">
    <svg :width="svgWidth" aria-hidden="true">
      <use :xlink:href="`/${domain}-sprites.svg#${name}`"></use>
    </svg>
    <span class="accessibility-sr-only" v-if="description">{{ description }}</span>
  </span>
</template>
<script setup lang="ts">
import type { IconName, IconSize } from './types';

export interface IconProps {
  description?: string;
  domain?: string;
  name: IconName;
  size?: IconSize;
}

const props = withDefaults(defineProps<IconProps>(), {
  description: undefined,
  domain: 'global',
  size: 'm'
});

/* SAFARI v15+ doesn't handle svg properly without setting a width explicitly,
only width is set because height breaks the actual aspect of icon  */
const svgWidth = computed(() => {
  switch (props.size) {
    case 'xxs':
      return 8;
    case 'xs':
      return 12;
    case 's':
      return 18;
    case 'm':
      return 24;
    case 'l':
      return 36;
    case 'xl':
      return 48;
    case 'xxl':
      return 84;
    default:
      return 24;
  }
});
</script>
<style lang="scss" scoped>
@use 'Icon.scss';
</style>
