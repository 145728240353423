<template>
  <button
    :aria-controls="ariaControls"
    :aria-label="ariaLabel"
    :class="[
      `Button Button--${variant} Button--${size}`,
      { 'Button--full': full },
      { 'Button--no-border': noBorder },
      { 'Button--is-loading': isLoading }
    ]"
    :disabled="disabled"
    @click="clickEmit"
    @keydown.enter="clickEmit"
    type="button"
    v-if="!isDecorative"
  >
    <slot v-if="!isLoading">
      <ux-atoms-typo :text="label" as="span" color="inherit" variant="inherit" />
    </slot>
    <div class="Button__loader-container" v-else>
      <ux-atoms-icon class="Button__loader" name="loader" size="s" />
      <span class="accessibility-sr-only">{{ $t('ux.atoms.button.isLoading') }}</span>
    </div>
  </button>
  <span
    :class="[
      `Button Button--decorative Button--${variant} Button--${size}`,
      { 'Button--full': full },
      { 'Button--no-border': noBorder },
      { 'Button--is-loading': isLoading }
    ]"
    :disabled="disabled"
    v-else
  >
    <slot v-if="!isLoading">
      <ux-atoms-typo :text="label" as="span" color="inherit" variant="inherit" />
    </slot>
    <div class="Button__loader-container" v-else>
      <ux-atoms-icon class="Button__loader" name="loader" size="s" />
      <span class="accessibility-sr-only">{{ $t('ux.atoms.button.isLoading') }}</span>
    </div>
  </span>
</template>
<script setup lang="ts">
import type { ButtonSize, ButtonVariant } from './types';

export interface Button {
  ariaControls?: string;
  ariaLabel?: string;
  disabled?: boolean;
  full?: boolean;
  isDecorative?: boolean;
  isLoading?: boolean;
  label?: string;
  noBorder?: boolean;
  size?: ButtonSize;
  variant?: ButtonVariant;
}

const emit = defineEmits(['button::click']);

const props = withDefaults(defineProps<Button>(), {
  ariaControls: undefined,
  ariaLabel: undefined,
  disabled: false,
  full: false,
  isDecorative: false,
  isLoading: false,
  label: undefined,
  noBorder: false,
  size: 'm',
  variant: 'primary'
});

const clickEmit = () => {
  if (!props.isLoading) {
    emit('button::click');
  }
};
</script>
